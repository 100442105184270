


export default function HomeTab({linkUrl, logo, text, title, type, linkUrlSim}) {
    return (
        <div
            className={"box-border cursor-pointer z-20 w-832 flex flex-col justify-between items-start rounded-lg overflow-hidden bg-battBlue p-4"}>
            <div className={"flex items-center"}>
                <img className={"h-7 w-auto"} src={logo} alt={""}/>
                <div className={"flex pl-2"}>
                    <div className={"text-white text-xl tracking-wide"}>
                        Batt-
                    </div>
                    <div className={"text-battGreen  text-xl  tracking-wide"}>
                        {title}
                    </div>
                </div>
            </div>
            <div className={"py-4 text-sm  text-white leading-5 text-left"}>{text}</div>
            {type=="sil"?<div className={"flex"}>
                <div onClick={()=>{window.open(linkUrl)}} className={"bg-battGreen py-2 flex  items-center  px-6 rounded-2xl"}>
                    <div className={"text-xs"}>
                        SIL
                    </div>
                    <img className={"h-5 pl-2"} src={require("../assets/right.png")}/>
                </div>
                <div onClick={()=>{window.open(linkUrlSim)}} className={"bg-battGreen ml-3 py-2 flex  items-center  px-6 rounded-2xl"}>
                    <div className={"text-xs"}>
                        SIM
                    </div>
                    <img className={"h-5 pl-2"} src={require("../assets/right.png")}/>
                </div>
            </div>:<div onClick={()=>{window.open(linkUrl)}} className={"bg-battGreen py-2 flex  items-center  px-6 rounded-2xl"}>
                <div className={"text-xs"}>
                    Get Started
                </div>
                <img className={"h-5 pl-2"} src={require("../assets/right.png")}/>
            </div>}
        </div>
    )
}
