import Logo from "../assets/Brand mark.png";
import LogoA from "../assets/Word mark.png";
import Help from "../assets/Group.png";
import Avatar from "../assets/3d_avatar_13.png";
import axios from "axios";
import {IoDocumentTextOutline} from "react-icons/io5";

export default function Navbar({userName}) {

    const goToDocs = ()=>{
        window.open(process.env.REACT_APP_BATTSTUDIO_DOCUMENTATION_URL)
    }

    const delete_cookie = function () {
        try {
            let realmName = process.env.REACT_APP_BATTKEY_REALM;
            let battKeyHost = process.env.REACT_APP_BATTKEY_URL;
            const logoutUrl = `${battKeyHost}/realms/${realmName}/protocol/openid-connect/logout`;
            axios.post(logoutUrl, {
                'client_id': process.env.REACT_APP_BATTKEY_CLIENTID,
                'refresh_token': localStorage.getItem("refreshToken")
            }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(() => {
                window.location.reload();
            })
        } catch (error) {
            console.log(error)
        }
    };

    return (
        <div className={"relative h-14 bg-lightestBlue py-2 px-4 flex justify-between items-center"}>
            <div className={"flex items-center"}>
                <img className={"h-8 w-8"} src={Logo} alt={'Battgenie logo'}/>
                <img className={"h-5 pl-3"} src={LogoA} alt={'Battgenie Title'}/>
            </div>
            <div className={"text-xl text-darkBlue font-bold"}>BATT-STUDIO</div>
            <div className={"flex"}>
                <div className={"flex items-center mr-8 cursor-pointer"}>
                    <img className={"h-5 w-auto mr-1"} src={Help} alt={""}/>
                    <div className={"pl-1"}>Help</div>
                </div>
                <div className={"flex"}>
                    <div onClick={goToDocs} className={"flex items-center mr-8 cursor-pointer"}>
                        <IoDocumentTextOutline size={22} color={"#222961"}/>
                        <div className={"pl-1"}>Documentation</div>
                    </div>
                </div>
                <div className={"flex items-center"}>
                    <div className={"mr-2"}>Hi, {userName}</div>
                    <img className={"h-8 w-auto"} src={Avatar} alt={""}/>
                </div>
                <div onClick={delete_cookie} className={"px-4 py-1 bg-battGreen ml-6 rounded-2xl cursor-pointer"}>
                    Logout
                </div>
            </div>
        </div>
    )
}
