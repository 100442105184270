import React, {useState} from 'react';
import './App.css';
import HomePage from "./routes/Home";
import Login from "./routes/Login";
import {ReactKeycloakProvider} from '@react-keycloak/web'
import {keycloak} from "./keycloak";

function App() {

    const [login, setLogin] = useState(false);
    const [loading, setLoading] = useState(true);
    const eventLogger = (event: unknown, error: unknown) => {
        console.log('onKeycloakEvent', event, error)

    }

    const tokenLogger = (tokens: any) => {
        setLoading(false);
        if(tokens?.idToken){
            localStorage.setItem("refreshToken", tokens.refreshToken)
            document.cookie = `jwt=${tokens?.idToken}; domain=${process.env.REACT_APP_COOKIE_DOMAIN}; path=/; expires=EXPIRATION_DATE;`;
            setLogin(true);
        }
    }

    return (
        <ReactKeycloakProvider
            initOptions={{"checkLoginIframe": false, onLoad: 'login-required' }}
            authClient={keycloak}
            onEvent={eventLogger}
            onTokens={tokenLogger}
        >
            {loading?<div className={"flex h-screen w-screen justify-center items-center"}>Loading...</div>:<div className="App">
                {login ? <HomePage keycloak={keycloak} /> : <Login/>}
            </div>}
        </ReactKeycloakProvider>
    );
}

export default App;
